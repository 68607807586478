<template>
    <div>
        <b-card title="Posodobitev podatkov iz blagajne">
            <b-button variant="primary" @click="updateCashRegisters"> Poslji request za pridobitev mankajočih EOR-jev
            </b-button>

            <div class="mt-5" v-if="failedCashRegisters && failedCashRegisters.length > 0">
                <div class="mb-2" style="font-size: 17px; font-weight: bold;">
                    Seznam blagajen, kamor je bil request poslan
                </div>

                <div v-for="(item, key) of failedCashRegisters" :key="'failedCashRegister_'+key">
                    <div style="font-weight: bold;">
                        Stranka: {{item.client_name}}
                    </div>

                    <div class="ml-4" v-for="(error, key) of item.errors" :key="'failedCashRegister_error_'+key + '_client_' + item.id">
                        - {{error}}
                    </div>
                </div>
            </div>

        </b-card>

        <b-card title="Pridobitev računa iz blagajne">
            <div style="margin-bottom: 7px;">Stranka</div>
            <v-select :options="clients" :reduce="ele => ele.id" v-model="billRequest.client_id" label="name" @input="loadCashRegisters">
                <template v-slot:option="option">
                    <div >
                        <span v-if="option.disabled">{{getOptionName(option.name, 30)}} - {{ getOptionName(option.alias, 20) }} <b-badge class="float-right mr-2" pill variant="danger">Onemogočen</b-badge></span>
                        <span v-else >{{getOptionName(option.name, 20)}} - {{ getOptionName(option.alias, 20) }}</span>
                    </div>
                </template>
            </v-select>

            <div class="mb-2" v-if="cash_registers && cash_registers.length > 0">
                <div class="mt-2" style="margin-bottom: 7px;">Blagajna</div>
                <v-select :options="cash_registers" :reduce="ele => ele.id" v-model="billRequest.cash_register_id" label="cash_register_id" > </v-select>
            </div>

            <b-form-group label="Identifikator računa (POXX-BGXX-XX)" v-if="billRequest.cash_register_id">
                <b-form-input v-model="billRequest.bill_identifier"></b-form-input>
            </b-form-group>

            <div class="mt-2 d-flex justify-content-end" >
                <b-button @click="getBill" variant="primary" :disabled="!(billRequest && billRequest.bill_identifier && billRequest.cash_register_id && billRequest.client_id)">Pridobi račun</b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
    import {BCard, BButton, BFormGroup, BFormInput, BBadge} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import '@core/scss/vue/libs/vue-select.scss'
    export default {

        components: {
            BBadge,
            BCard,
            BButton,
            BFormGroup,
            BFormInput,
            vSelect
        },
        data() {
            return {
                failedCashRegisters: [],
                clients: [],
                cash_registers: [],
                billRequest: {
                    bill_identifier: '',
                    client_id: null,
                    cash_register_id: null
                }
            }
        },
        methods: {
            async getBill() {
                try {
                    await this.$http.post('/api/admin/v1/issued_bills/issued_bill', this.billRequest)
                } catch (err) {
                    this.$printError('Prišlo je do napake', 3000)
                }
            },
            async loadCashRegisters() {

                this.billRequest.bill_identifier = ''
                this.billRequest.cash_register_id = null

                if (!this.billRequest.client_id) {
                    this.cash_registers = []
                    return
                }

                try {
                    const res = await this.$http.get(`/api/admin/v1/cash_registers/${this.billRequest.client_id}`)
                    this.billRequest.cash_register_id = null
                    this.billRequest.bill_identifier = ''
                    this.cash_registers = res.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake', 3000)
                }
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const response = await this.$http.get('/api/admin/v1/client/')
                    this.clients = response.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake', 3000)
                } finally {
                    this.showLoader = false
                }
            },
            async updateCashRegister(client) {
                try {
                    const response = await this.$http.get(`/api/admin/v1/cash_register_update/update_eor/${  client.id}`)
                    this.failedCashRegisters.push({
                        id: client.id,
                        client_name: client.name,
                        errors: response.data ?? []
                    })
                } catch (err) {
                    this.failedCashRegisters.push({
                        id: client.id,
                        client_name: client.name,
                        errors: [err]
                    })
                    this.$printError('Prišlo je do napake pri pridobivanju podatkov')
                }
            },
            async updateCashRegisters() {

                for (const client of this.clients) {
                    await this.updateCashRegister(client)
                }

                this.$printSuccess('Requst poslan na vse online blagajne')
            },
            getOptionName(nameIn, len = 40) {
                let name = nameIn.substring(0, len)
                if (name.length === len) {
                    name += '...'
                }

                return `${name}`
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>